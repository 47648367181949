import {Link, withPrefix} from 'gatsby';
import React from 'react';

import {Button} from '../../../components/button';
import {Layout} from '../../../components/layout';

export default ({data}: any) => (
    <Layout
        title="Uitgebreide toelichting op onze werkwijze en voorwaarden"
        description="Opdrachtgevers ontvangen voorafgaand aan een softwareontwikkelingstraject, naast het voorstel, ook de volledige toelichting op onze werkwijze en voorwaarden."
        ogImage="uitgebreide-toelichting.png"
        translatedPagePath="/en/terms-and-conditions/explanation/">
        <section className="section">
            <article className="content content--no-hero">
                <div className="container">
                    <div className="content__display">
                        <h2>
                            {/* Make sure to have the correct spacing when br becomes invisible */}
                            Toelichting <br /> Dienst&shy;verlening&nbsp;&amp; <br /> Werkwijze
                        </h2>
                        <div className="display__number">DW</div>
                    </div>
                    <div className="content__copy">
                        <p className="p-lead">
                            Opdracht&shy;gevers ont&shy;vangen voor&shy;afgaand aan een ont&shy;wikkelings&shy;traject,{' '}
                            naast het voorstel en het contract, ook de{' '}
                            <span className="highlight">volledige toelichting</span> op onze werkwijze en voorwaarden in{' '}
                            klare taal.
                        </p>
                        <p>
                            Op deze website vind je een mooi overzicht van{' '}
                            <Link to="/nl/werkwijze/" title="Onze aanpak">
                                onze aanpak
                            </Link>
                            . Opzoek naar meer details? Download hieronder de uitgebreide toelichting waarin alles te{' '}
                            vinden is over onze dienstverlening en werkwijze.
                        </p>
                        <p>
                            In deze publieke versie zijn onze tarieven verborgen. Wil je graag samenwerken? Neem{' '}
                            <Link to="/nl/contact/" title="Contactgegevens Enschede">
                                contact
                            </Link>{' '}
                            op, en we beantwoorden graag alle vragen in een verkennend gesprek.
                        </p>
                    </div>

                    <div className=" content__buttons content__buttons--50">
                        <Button
                            direct
                            url={withPrefix('/files/dienstverlening-werkwijze.pdf')}
                            name="Dienstverlening & werkwijze (PDF)"
                            className="button--download"
                            title="Download onze uitgebreide toelichting op onze dienstverlening en werkwijze als PDF"
                        />
                    </div>
                </div>
            </article>
        </section>
    </Layout>
);
